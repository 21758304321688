<template>
  <tr @click="openRole(role.id)" style="cursor:pointer;" class="tableRow">
    <td>{{ role.title }}</td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    role: Object,
  },
  methods: {
    openRole(id) {
      this.$router.push({ name: 'Role', params: { roleId: id } })
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
  },
}
</script>

<style scoped lang="scss">



</style>