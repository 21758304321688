<template>
  <div class="pageWrapper">
    <div class="topbar">
      <div style="display: flex">
        <H2>Roles</H2>
      </div>
      <MainButton
        :clickFunction="openAddRole"
        title="Add role"
        styleClass="primary"
      />
    </div>
    <SearchInput placeholder="Search" v-model="searchString" />
    <LoadingRowTable v-if="!roles" :color="'#444'" />
    <table v-else class="standardTable shopsTable">
      <br />
      <tr class="standardTableHeader">
        <th>Role</th>
      </tr>
      <RoleRowItem :key="role.id" :role="role" v-for="role in filteredRoles" />
    </table>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

import AddRolePopupBox from '@/components/management/AddRolePopupBox'
import RoleRowItem from '@/components/management/RoleRowItem'
import LoadingRowTable from '@/components/LoadingRowTable'
import SearchInput from '@/layout/SearchInput'
import MainButton from '@/layout/MainButton'
import Search from '@/components/Search'
import H2 from '@/layout/typography/H2'

export default {
  components: { RoleRowItem, LoadingRowTable, MainButton, Search, SearchInput, H2 },
  data() {
    return {
      roles: undefined,
      shopName: undefined,
      searchString: ''
    }
  },
  computed: {
    filteredRoles() {
      if (!this.searchString) {
        return this.roles
      }
      return this.roles.filter((role) => {
        console.log(role);
        if (role.title.toLowerCase().includes(this.searchString.toLowerCase())) {
          return role
        }
      })
    },
    ...mapGetters({
      isMobile: 'isMobile',
    }),
  },
  created() {
    this.getRoles()
  },
  methods: {
    openAddRole() {
      this.$store.commit('SET_POPUP', { component: AddRolePopupBox, properties: { getRoles: this.getRoles } })
    },
    getRoles() {
      axios.get('roles/getRoles', {}).then((response) => {
        this.roles = response.data
      })
    },
  },
}
</script>

<style scoped lang="scss">
/* .shopsTable {
  width: 50%;
  position: relative;
} */
.addShopWrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.addShop span input:focus {
  border-bottom: 1px solid rgb(139, 176, 255);
}

.addShop span input {
  font-size: 8pt;
  position: relative;
  border: none;
  border-bottom: 1px solid lightgray;
  transition: 0.2s ease-in-out;
}

.addShop span {
  display: inline-flex;
  position: relative;
  margin: 10px;
  padding: 10px;
}

.addShop {
  background: white;
  position: relative;
  width: 50%;
  transition: 0.2s ease-in-out;
  display: inline-flex;
  border-radius: 7px;
  height: 0px;
  display: block;
  overflow: hidden;
  margin: 10px 0;
}
.button {
  border: none;
  background: rgb(24, 185, 51);
  color: white;
  border-radius: 3px;
  font-family: 'AvenirRound', sans-serif;
  padding: 5px 7px;
  border: 1px solid rgb(24, 185, 51);
  transition: 0.2s ease-in-out;
}
</style>