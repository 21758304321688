<template>
  <div class="searchWrapper">
    <div class="searchInput">
      <form @submit.prevent autocomplete="off">
        <input
          @keydown.down="changeValue(1)"
          @keydown.enter="addSelectedProduct()"
          @keydown.up="changeValue(-1)"
          v-model="searchString"
          autocomplete="false"
          type="text"
          :placeholder="placeholder"
        />
      </form>
      <ion-icon class="searchIcon" name="search-outline"></ion-icon>
    </div>
    <div v-if="searchString != ''" id="searchResult" class="searchResult">
      <div v-if="addFromSearch">
        <div
          @click="
            addFromSearch.func()
            searchString = ''
          "
          v-if="addFromSearch.display"
          class="resultData"
        >
          <i class="fas fa-user-plus"></i>
          <span>Add as new user</span>
        </div>
      </div>
      <div
        :style="selected(i)"
        :productId="i"
        :ref="'product' + i"
        @click="
          returnFunction(data)
          searchString = ''
        "
        class="resultData"
        v-for="(data, i) in searchData"
        :key="i"
      >
        <i :class="'fas ' + icon"></i>
        <span>{{ data[displayKey] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { accent } from './../assets/css/variables/theme.scss'

export default {
  props: [
    'continueButton',
    'addFromSearch',
    'dataFunction',
    'returnFunction',
    'placeholder',
    'displayKey',
    'icon',
  ],
  data() {
    return {
      searchString: '',
      searchData: '',
      currentSelect: 0,
      realScroll: 0,
    }
  },
  created() {
    if (this.addFromSearch.display) {
      this.currentSelect += 1
    }
  },
  methods: {
    focusContinue() {
      this.continueButton.getElementsByTagName('button')[0].style.border =
        '2px solid black'
    },
    scrollToEnd: function (val) {
      const container = this.$el.querySelector('#searchResult')
      container.scrollTop = val
    },
    addSelectedProduct() {
      this.$refs[String('product' + this.currentSelect)][0].click()
    },
    selected(val) {
      if (val == this.currentSelect) {
        return 'border:1px solid ' + accent + ';'
      }
    },
    changeValue(increment) {
      if (this.searchString.length == 0) {
        this.continueButton.getElementsByTagName('button')[0].focus()
        this.continueButton.getElementsByTagName('button')[0].style.border =
          '2px solid black'
        return
      }

      if (this.currentSelect + 1 == this.searchData.length) {
        this.scrollToEnd(this.realScroll)
      } else if (
        this.currentSelect > 2 &&
        this.addFromSearch.display == false
      ) {
        this.realScroll += 45 * increment
        this.scrollToEnd(this.realScroll)
      } else if (this.currentSelect > 1 && this.addFromSearch.display == true) {
        this.realScroll += 45 * increment
        this.scrollToEnd(this.realScroll)
      } else {
        this.realScroll = 0
        this.scrollToEnd(0)
      }

      if (
        this.currentSelect + increment == -1 ||
        this.currentSelect + increment == this.searchData.length
      ) {
        return
      }

      this.currentSelect += increment
    },
  },
  watch: {
    async searchString() {
      this.currentSelect = 0
      this.searchData = await this.dataFunction(this.searchString)
    },
  },
}
</script>

<style scoped lang="scss">
.searchInput {
  position: relative;
  width: 100%;
  margin-bottom: 5px;
}
.resultData i {
  font-size: 13pt;
  margin-right: 10px;
}
.resultData:hover {
  background: #f3f3f3;
}
::-webkit-scrollbar {
  width: 0px;
}
.searchResult {
  border: 1px solid white;
  overflow: scroll;
  background: white;
  z-index: 10;
  max-height: 200px;
}

.resultData {
  cursor: pointer;
  transition: 0.2s ease-in-out;
  position: relative;
  border-radius: 3px;
  width: 100%;
  padding: 10px;
  margin: 3px 0;
  border: 1px solid white;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
  display: flex;
}

.searchWrapper {
  z-index: 2;
  height: 40px;
  max-width: 340px;
  position: relative;
  margin: 10px 0;
}

.searchWrapper input {
  border: none;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background: #f3f3f3;
}

.searchWrapper input:focus {
  border: 1px solid $accent;
}

.searchIcon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
</style>